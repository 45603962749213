<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
      <div class="content-main content-auto">
        <ayl-table :table="table"></ayl-table>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      const vm = this
      return {
        nav: [
          {name: '会计对账'},
          {name: '内部驾驶员统计'},
        ],
        table: {
          api: vm.$api.ListDriverAccountCheckingInfo,
          query: {
            orderStatus: '已核算',
            startTime: null,
            endTime: null,
            orderType: null,
            driverType: '内部司机',
            queryContent: null,
          },
          searchData: [{
            type: 'dateSection',
            title: '查询日期',
            isOptions: true,
            model: [],
            modelName: ['startTime', 'endTime']
          }, {
            type: 'select',
            title: '业务类型',
            model: 'orderType',
            placeholder: '请选择',
            option: vm.$enums.OrderType.list
          }, {
            type: 'input',
            model: 'queryContent',
            placeholder: '驾驶员姓名'
          }],
          columns: [{
            title: '驾驶员',
            key: 'driverName'
          }, {
            title: '手机号',
            key: 'contactPhone',
          }, {
            title: '提成系数(%)',
            key: 'driverBonusFee',
          }, {
            title: '订单数',
            key: 'orderCount',
          }, {
            title: '操作',
            width: '100px',
            render (h, ctx) {
              return h('span', [
                h('span', {
                  class: {
                    'table-view': true,
                  },
                  on: {
                    click: vm.goExamine.bind(this, ctx.row)
                  }
                }, '承运明细')
              ])
            }
          }]
        }
      }
    },
    methods: {
      goExamine (e) {
        this.$router.push({
          path: '/accounting/inside-detailed',
          query: {
            driverId: e.driverId,
            driverName: e.driverName,
            startTime: this.table.query.startTime,
            endTime: this.table.query.endTime,
            orderType: this.table.query.orderType
          }
        })
      }
    },
    beforeRouteEnter (to, from, next) {
      if (from.path === '/accounting/inside-detailed') {
        to.meta.isBack = true
      } else {
        to.meta.isBack = false
      }
      next()
    },
    async activated () {
      if (!this.$route.meta.isBack) {
        this.table.query.orderType = null,
        this.$set(this.table.query,'startTime',null),
        this.$set(this.table.query,'endTime',null),
        this.table.searchData[0].model = [],
        this.table.searchData[0].modelName = ['startTime','endTime'],
        this.table.query.queryContent = null;
        // await this.$search(this.table)
      } else {
          // await this.$search(this.table)
          this.$route.meta.isBack = false // 重置详情页标识isBack
      }
    },
    async mounted () {
      // await this.$search(this.table)
    }
  }
</script>

<style lang='sass' scoped>

</style>
